[
  {
    "title": "Hardware",
    "title2": "senseBox",
    "description": "The 'Do-It-Yourself' Cititzen Science environmental measuring station - for use at home, in schools, universities and other educational and research institutions, companies, cities or communities. Configure the station with sensors according to your needs and transfer the environmental data via LoRa, WiFi or LAN to the Internet. We develop custom-fit solutions for your problem around sensors and the Internet of Things. Discover our project portfolio on the hardware page",
    "link": "portfolio?angebot=Hardware",
    "linkText": "Click to see Portfolio",
    "image": "/assets/img/angebote/hardware.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Software",
    "title2": "Digitale Bildung & Citizen Science",
    "description": "Whether web-based geo-information system, individual learning management system, data analysis software, research data infrastructure, learning apps or citizen science apps. Our experienced team conceives, designs and develops solutions for education, science and participation. Discover our software project portfolio and contact us.",
    "link": "portfolio?angebot=Software",
    "linkText": "Click to see Portfolio",
    "image": "/assets/img/angebote/software.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Events",
    "title2": "Training courses, Workshops and Hackathons",
    "description": "We organize and host events for children, young people and adults. For example:",
    "list1": "Hackathon with tent camp at a university",
    "list2": "Holiday workshops for kids in companies",
    "list3": "Coding workshops in museums or libraries",
    "list4": "Teacher training in school",
    "list5": "Citizen workshop in a repair cafe",
    "list6": "Online hackathon",
    "end": "Take a look at our previous events and contact us.",
    "link": "portfolio?angebot=Veranstaltungen",
    "linkText": "Click to see Portfolio",
    "link2": "workshops",
    "link2Text": "More information and contact",
    "image": "/assets/img/angebote/veranstaltungen.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Educational Resources",
    "title2": "Videos, tutorials, didactic concepts",
    "description": "For different target groups we develop (if possible under open licenses) teaching and learning materials, so-called Open Educational Resources. These can be static or interactive documents and tutorials in the sense of 'worksheets', YouTube video tutorials, textbooks, flashcards or content for other applications. Our materials are in use in schools, museums and in projects of various foundations. See for yourself and develop your material together with us.",
    "link": "portfolio?angebot=lehrmaterial",
    "linkText": "Click to see Portfolio",
    "image": "/assets/img/angebote/lernmaterial.jpeg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Science",
    "title2": "Our contribution to science",
    "description": "As a start-up company (SME), we are a partner in several research consortia and take on various tasks and subcontracts in research projects of different funding agencies, such as the Federal Ministry of Education and Research, the EU or the Federal Ministry of Economics and Innovation.Our research methods include:",
    "list1": "Design and implementation of user studies (e.g. Usability studies)",
    "list2": "Development of research software, cloud-based research data inquiry structures and databases.",
    "list3": "Development and evaluation of environmental sensor systems",
    "list4": "Geostatistical data analysis",
    "list5": "Communication and dissemination of research results",
    "end": "Take a look at our research portfolio and contact us.",
    "link": "/forschung",
    "linkText": "Our scientific projects",
    "link2": "portfolio?angebot=Forschung",
    "link2Text": "Click to see Portfolio",
    "image": "/assets/img/angebote/forschung.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Consulting",
    "title2": "Strategy, concepts, implementation",
    "description": "We advise you and develop a digitization strategy together with you that fits your problem . With our experience in  implementation of projects in the areas of digital education, crowdsourcing, citizen science, data analysis, smart city, Internet of Things and our background in the research context, we support you in your project.",
    "link": "portfolio?angebot=Beratung",
    "linkText": "Click to see Portfolio",
    "image": "/assets/img/angebote/beratung.jpg",
    "underscoreStyle": "dark"
  }
]
