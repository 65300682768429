import { Component, OnInit } from '@angular/core';

@Component({
    selector: 're-philosophie',
    templateUrl: './philosophie.component.html',
    styleUrls: ['./philosophie.component.scss'],
    standalone: false
})
export class PhilosophieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
