[
  {
    "name": "Dr. Thomas Bartoschek",
    "position": "Geschäftsführer",
    "title": "Ph.D. Geoinformatik",
    "tags": ["Science", "Education", "Speaking", "Sales", "UX", "Vision"],
    "email": "t.bartoschek@reedu.de",
    "photo": "/assets/img/team/barto_new.jpg"
  },
  {
    "name": "Jan Wirwahn",
    "position": "Co-Founder & Entwickler",
    "title": "M.Sc. Geoinformatik",
    "tags": ["Open Source Hardware", "IoT", "Education", "Camping & Kayaking"],
    "email": "j.wirwahn@reedu.de",
    "photo": "/assets/img/team/jan_new.jpg"
  },
  {
    "name": "Umut Taş",
    "position": "Geschäftsführer & Entwickler",
    "title": "M.Sc. Geoinformatik",
    "tags": [
      "Data Visualization",
      "Maps",
      "Open Data",
      "Angular",
      "Node",
      "React"
    ],
    "email": "u.tas@reedu.de",
    "photo": "/assets/img/team/umut_new.jpg"
  },
  {
    "name": "Felix Erdmann",
    "position": "Entwickler",
    "title": "M.Sc. Geoinformatik",
    "tags": ["Maps", "React", "Node", "Mongo", "IoT", "GIS"],
    "email": "f.erdmann@reedu.de",
    "photo": "/assets/img/team/felix_new.jpg"
  },
  {
    "name": "Verena Witte",
    "position": "Bildung",
    "title": "M.Ed. Mathematik & Geographie",
    "tags": ["Bildung", "Workshops", "Projekttage", "Bildungsmaterial"],
    "email": "v.witte@reedu.de",
    "photo": "/assets/img/team/verena_new.jpg"
  },
  {
    "name": "Gina Buchwald-Chassée",
    "position": "Kommunikation",
    "title": "M.A. Kommunikationswissenschaft",
    "tags": ["Unternehmenskommunikation", "Social Media"],
    "photo": "/assets/img/team/gina_new.jpg"
  },
  {
    "name": "Eduardo Candeias Schneider",
    "position": "Werkstudent",
    "title": "B.A. Erziehungswissenschaft & Informationsverarbeitung",
    "tags": ["Bildung", "Workshops", "Bildungsmaterial"],
    "photo": "/assets/img/team/EduardoCandelas_067.JPG"
  },
  {
    "name": "Artur Kim Shum",
    "position": "Innovation Designer",
    "title": "B.A. Architektur und Stadtplanung",
    "tags": ["UX", "Prototyping", "Data Visualization", "Open Data"],
    "photo": "/assets/img/team/KIMSHUM.png"
  },
  {
    "name": "Eric Thieme-Garmann",
    "position": "Entwickler",
    "title": "M.Sc. Geoinformatik",
    "tags": ["IoT", "Angular", "Node", "React", "Citizen Science"],
    "email": "e.thieme@reedu.de",
    "photo": "/assets/img/team/Eric.png"
  },
  {
    "name": "Sergey Mukhametov",
    "position": "Co-Founder",
    "title": "M.Sc. Geoinformatik",
    "tags": ["Science", "Museen & Ausstellungen"],
    "email": "s.mukhametov@reedu.de",
    "photo": "/assets/img/team/sergey_new.jpg"
  },
  {
    "name": "David Fehrenbach",
    "position": "Co-Founder",
    "title": "M.Sc. BWL",
    "tags": ["Business operations"],
    "photo": "/assets/img/team/david_new.jpg"
  },
  {
    "name": "Georgi Semov",
    "position": "Entwickler",
    "title": "Auszubildender",
    "tags": ["IoT", "Arduino"],
    "photo": "/assets/img/team/Georgi.png"
  },
  {
    "name": "Paula Scharf",
    "position": "Entwicklerin",
    "title": "M.Sc. Geoinformatik",
    "tags": ["Web development", "IoT", "AI"],
    "photo": "/assets/img/team/Paula_Scharf_Foto.png"
  },
  {
    "name": "Kieran Galbraith",
    "position": "Werkstudent, Softwareentwickler",
    "title": "",
    "tags": ["Python", "Java Script"],
    "photo": "/assets/img/team/Kieran.jpg"
  },
  {
    "name": "Ronja Federer",
    "position": "Office Management",
    "title": "&#8203;",
    "tags": ["Ordnung", "Yoga"],
    "email": "r.federer@reedu.de",
    "photo": "/assets/img/team/ronja.jpg"
  },
  {
    "name": "Mostafa Kandel",
    "position": "Entwickler",
    "title": "M.Sc. Elektrotechnik",
    "tags": ["Python", "Node", "React","JavaScript"],
    "photo": "/assets/img/team/mostafa.jpg"
  },
  {
    "name": "Maria Zadnepryanets",
    "position": "Entwicklerin",
    "title": "M.Sc. Ingenieurwesen und Technologie",
    "tags": ["React", "JavaScript"],
    "photo": "/assets/img/team/maria.jpg"
  },
  {
    "name": "Matteo Weickert",
    "position": "Bildung",
    "title": "Werkstudent",
    "tags": ["AGs", "Projekttage", "Machine Learning"],
    "photo": "/assets/img/team/matteo.jpg"
  }
]
