[
  {
    "name": "senseBox:bike",
    "desc": "Die senseBox:bike ist eine KI-basierte Open Source-Sensortechnologie zur mobilen Erhebung von Umwelt- und Verkehrsdaten mit dem Fahrrad (z.B. enge Überholungen, Wegbeschaffenheit, Feinstaubbelastung, Temperatur etc.), die zur Verbesserung der Fahrradinfrastruktur im urbanen Raum beitragen können.",
    "img": "/assets/img/projekte/senseBox-bike-logo.png",
    "link": "https://sensebox.de/de/products-bike",
    "topics": ["KI", "Hardware", "Mobilität"],
    "customer": "",
    "angebote": ["Hardware"],
    "year": [2024],
    "target": ["Bürger:innen","Wissenschaft", "Städte"]
  },
  {
    "name": "Mapstories 2.0",
    "desc": "Mit Mapstories werden Geschichten rund um die Welt erzählt. Das Tool bietet vielfältige Optionen, um globale Zusammenhänge mithilfe von Orten als Stationen auf einem Globus zu veranschaulichen. Mapstories ist im Jahr 2021 im Rahmen einer Zusammenarbeit zwischen re:edu und Vamos e.V. entstanden. Der Ausgangspunkt des Projektes “welt.weit.virtuell” war die Durchführung eines ko-kreativen Labs zur globalen Bekleidungsindustrie. Nachdem viele positive Rückmeldungen zum Tool und zahlreiche Anpassungswünsche geäußert wurden, wurde Mapstories im Juni 2023 in einer neuen Version veröffentlicht. Diese Version bringt eine Fülle von Verbesserungen und neuen Funktionen mit sich, wie z.B. ein Story Lab.",
    "img": "/assets/img/projekte/Mapstories 2.0.png",
    "link": "https://mapstories.de/de",
    "topics": ["DigitaleBildung", "WebDevelopment", "GlobalesLernen"],
    "customer": "Vamos e.V.",
    "angebote": ["Software", "Veranstaltung"],
    "year": [2023,2024],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "Klimadashboard",
    "desc": "Wo steht Münster auf dem Weg zur Klimaneutralität? Dafür haben wir ein Klimadashboard für die Stabsstelle Smart City und die Stabsstelle Klima der Stadt Münster mit Unterstützung von den Stadtwerken Münster und der Wirtschaftsförderung Münster entwickelt. Es wird im Rahmen des Förderprogramms „Modellprojekte Smart Cities“ aus Mitteln des Bundesministeriums für Wohnen, Stadtentwicklung und Bauwesen (BMWSB) gefördert. Das Klimadashboard zeigt aktuelle Daten zu Klima, Energie, Mobilität und Gebäuden als wichtige Indikatoren von Klimaschutz und ermöglicht so Anpassungen an die Folgen des Klimawandels und schafft mehr Transparenz in der gesamtstädtischen Klimaarbeit.",
    "img": "/assets/img/projekte/Klimadashboard_MS.png",
    "link": "https://klimadashboard.ms/",
    "topics": ["SmartCity", "WebDevelopment", "OpenData"],
    "customer": "Stadt Münster, Stabsstelle Smart City",
    "angebote": ["Software"],
    "year": [2023,2024],
    "target": ["Städte", "Bürger:innen"]
  },
  {
    "name": "OER4SDI",
    "desc": "Das Projekt OER4SDI – Open Educational Resources for Spatial Information Infrastractures - verfolgt das Ziel, offenes Unterrichtsmaterial zu Themen der Geoinformationsverarbeitung für Studierende zu konzipieren und auf der OER-Plattform ORCA.NRW zu veröffentlichen. Während sich das Institut für Geoinformatik der Universität Münster sowie die Ruhr-Universität und Hochschule Bochum auf die Erstellung der Materialien fokussieren, durfte re:edu seine Expertise im Bereich des Designs einbringen. Zu den Aufgaben gehörten folglich die Erstellung von grundlegenden Designelementen, wie ein Logo, aber auch die Gestaltung der H5P Interactive Books zur Integration der konzipierten Materialien.",
    "img": "/assets/img/projekte/OER4SDI.png",
    "link": "https://www.hochschule-bochum.de/fbg/forschung-und-entwicklung/oer4sdi/",
    "topics": ["OER", "DigitaleBildung"],
    "customer": "Hochschule Bochum",
    "angebote": ["Software"],
    "year": [2023],
    "target": ["Studierende"]
  },
  {
    "name": "CENOGRID-Megasplice",
    "desc": "Die Webseite cenogrid.org bietet Zugang zu veröffentlichten benthischen δ13C- und δ18O-Daten, die in mehr als 50 Jahren Tiefseebohrungen im Rahmen des IODP und seiner Vorgängerprogramme gesammelt wurden. Es können eigene CENOGRID-Megasplice konstruiert werden, einschließlich eigener Daten, um den Klimawandel und den Kohlenstoffkreislauf der letzten 66 Millionen Jahre zu visualisieren. Die Daten können selbst hochgeladen und mit den Referenzdaten verglichen werden.",
    "img": "/assets/img/projekte//megasplice.jpg",
    "link": "https://cenogrid.org/",
    "topics": ["WebDevelopment", "OpenData"],
    "customer": "Universität Münster",
    "angebote": ["Software"],
    "year": [2023],
    "target": ["Wissenschaft"]
  },
  {
    "name": "Mapstories – zeitgeisty",
    "desc": "Mit der Implementation von zeitgeisty in das StoryLab von Mapstories.de ist es nun möglich, Zeitstempel zu setzen und somit Orte und Zeitpunkte zu verknüpfen. Dadurch werden die globalen Geschichten und Zusammenhänge um die zeitliche Komponente erweitert und man kann historisch verfolgen, wann ein Ereignis an einem bestimmten Ort stattgefunden hat. Mit zeitgeisty wird so z.B. eine Mapstory zur Geschichte des 20. Jahrhunderts erstellt.",
    "img": "/assets/img/projekte/zeitgeisty.png",
    "link": "https://bunker-ulmenwall.org/archiv/zeitgeisty_archiv22/",
    "topics": ["DigitaleBildung", "WebDevelopment", "GlobalesLernen"],
    "customer": "Vamos e.V.",
    "angebote": ["Software", "Veranstaltung"],
    "year": [2023,2024],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "Homepage für das Spatial Intelligence Lab der Universität Münster",
    "desc": "Design und Entwicklung von Wordpress-basierten Homepages für das Spatial Intelligence Lab(SIL) des Institut für Geoinformatik an der Universität Münster.",
    "img": "/assets/img/projekte/logo_silhomepage.png",
    "link": "http://bachelor-geoinformatik.de",
    "topics": ["WebDevelopment", "Wordpress", "HigherEducation", "WebDesign"],
    "customer": "Universität Münster",
    "angebote": ["Software", "Beratung"],
    "year": [2022],
    "target": ["Student:innen", "Schüler:innen", "Hochschulen"]
  },
  {
    "name": "PVP-Map",
    "desc": "Entwicklung einer webbasierten Plattform zur Darstellung von interaktiven Karten zur Planung, Evaluation und Entwicklung des Praxissemesters von Lehramtsstudierenden an den NRW-Hochschulen.",
    "img": "/assets/img/projekte/webgis-nrw.png",
    "link": "",
    "topics": ["Lehramt","Praktikum","Web","Maps","GIS"],
    "customer": "Ministerium für Schule und Bildung des Landes NRW über WWU Münster, und 52°north",
    "angebote": ["Software"],
    "year": [2022],
    "target": ["Hochschulen","Schulen"]
  },
  {
    "name": "3-2-1 heiss!",
    "desc": "Citizen Science Projekt zur Erkennung von Hitzeinseln im Kanton Aargau mit Hilfe einer mobilen senseBox in einem indivuellen Gehäuse mit Aktivlüftung.",
    "img": "/assets/img/projekte/logo_321heiss.png",
    "link": "https://www.catta.ch/321heiss",
    "topics": ["Citizen Science"],
    "customer": "catta GmbH",
    "angebote": ["Software","Hardware"],
    "year": [2022],
    "target": ["Bürger:innen","Wissenschaft"]
  },
  {
    "name": "CS:iDrop",
    "desc": "Was passiert mit unserem Leitungswasser auf dem Weg von der Hausübergabestation zum Wasserhahn? Beeinflussen Leitungen und Armaturen einzelne Parameter der Wasserqualität? Und wenn ja, wie groß ist das Ausmaß dieser Beeinflussung? Diesen und weiteren Fragen rund um den „letzten Meter“ unseres Trinkwassers gehen Bürger:innen der Pilotregion Bochum und Wissenschaftler:innen der Ruhr-Universität Bochum im Projekt „CS:iDrop – Citizen Science: investigation of Drinking-water of and by the public“ nach. Reedu begleitet dieses Projekt durch die Entwicklung der benötigen Smartphone Applikation",
    "img": "/assets/img/projekte/logo2_csidrop.png",
    "link": "https://www.ruhr-uni-bochum.de/didachem/CSiDrop.htm",
    "topics": ["Citizen Science"],
    "customer": "Ruhr-Univesität Bochum",
    "angebote": ["Software","App Development"],
    "year": [2022],
    "target": ["Bürger:innen"]
  },
  {
    "name": "re:quest",
    "desc": "Im Projekt re:quest findet die Vermittlung von Programmier- und Datenkompetenzen über digitale Escape Games statt. Kinder, Jugendliche und junge Erwachsene lösen die Rätsel und Aufgaben des Escape Games, indem sie programmieren und Daten explorieren und analysieren.",
    "img": "/assets/img/projekte/logo_request.png",
    "link": "https://request.reedu.de/",
    "topics": ["DigitaleBildung"],
    "customer": "Stifterverband, AWS",
    "angebote": ["Software"],
    "year": [2022],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
      {
    "name": "mybadges",
    "desc": "myBadges ist ein auf dem Open Badge-Standard basierendes System, das im schulischen wie außerschulischen Kontext Einsatz finden kann. Durch ein E-Portfolio werden Badges kontextualisiert und so Lernleistungen, Kompetenzerwerb, Erfolge und Teilnahmen dokumentiert. Im Rahmen des Hackathons #WirFürSchule 2020 wurde mit einem interdisziplinären Team die Projektidee weiterentwickelt und ein erster Prototyp umgesetzt, der den 1.Platz gewonnen hat. Dieser Prototyp wird im senseBox Pro Projekt an die senseBox Lernumgebung und die openSenseMap angedockt und mit Unterstützung der Telekom Stiftung ausgebaut und in einem Pilotprojekt in verschiedenen Bonner Bildungseinrichtungen eingesetzt.",
    "img": "/assets/img/projekte/myBadges.svg",
    "link": "https://mybadges.org",
    "topics": ["DigitaleBildung"],
    "customer": "Telekom Stiftung",
    "angebote": ["Software"],
    "year": [2024,2023,2022,2021],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
     {
    "name": "Digtial-AG in der Grundschule",
    "desc": "Im offenen Ganztag der Annette-von-Droste-Hülshoff Grundschule Münster-Nienberge bieten wir wöchentlich für alle Kinder, die Freude am Umgang mit digitalen Medien haben und gerne einmal einen Blick hinter die Funktionsweisen dieser werfen möchten, die Digital-AG an.",
    "img": "/assets/img/projekte/Digital-AG.jpg",
    "link": "",
    "topics": ["DigitaleBildung"],
    "customer": "Amt für Kinder, Jugendliche und Familien der Stadt Münster",
    "angebote": ["Veranstaltung"],
    "year": [2022,2021],
    "target": ["Schüler:innen"]
  },
    {
    "name": "App-Entwicklung: Invasive Fremdarten in Europa",
    "desc": "Weiterentwicklung der App \"Invasive Fremdarten in Europa\", um Informationen über invasive gebietsfremde Arten in Europa zu erhalten und mit anderen zu teilen.",
    "img": "/assets/img/projekte/App-Invasive-Fremdarten-Europa.png",
    "link": "https://play.google.com/store/apps/details?id=eu.europa.publications.mygeossias&hl=de&gl=US",
    "topics": ["CitizenScience", "AppEntwicklung", "Umwelt"],
    "customer": "Europäische Union",
    "angebote": ["Software"],
    "year": [2021],
    "target": ["Bürger:innen", "Wissenschaft"]
  },
  {
    "name": "Humboldt Explorers",
    "desc": "Entwicklung einer kartenbasierten Webplattform zur Darstellung und Analyse von Umweltdaten. Entwicklung einer individuellen senseBox für Umweltdatenaufnahme des mobilen Schülerlabors \"Humboldt Explorers\" der Humboldt-Universität Berlin.",
    "img": "/assets/img/projekte/Humboldt-Explorers.png",
    "link": "https://www.humboldt-bayer-mobil.de/de",
    "topics": ["CitizenScience", "WebDevelopment", "DataLiteracy", "DigitaleBildung", "Umwelt", "MINT"],
    "customer": "Humboldt-Universität Berlin",
    "angebote": ["Software", "Hardware"],
    "year": [2022,2021],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "openSenseMap",
    "desc": "Entwicklung, Umzug, Wartung und Anpassung der Infrastruktur der openSenseMap.",
    "img": "/assets/img/projekte/opensensemap.png",
    "link": "https://opensensemap.org",
    "topics": ["CitizenScience", "ProfessionalService", "OpenData", "Maps", "Sensors", "Geoinformation"],
    "customer": "Universität Münster",
    "angebote": ["Software", "Beratung"],
    "year": [2021, 2020],
    "target": ["Schüler:innen", "Bürger:innen", "Wissenschaft"]
  },
  {
    "name": "GeoGami",
    "desc": "Entwicklung einer spielbasierten App zum räumlichen Lernen und als Forschungswerkzeug im Bereich Spatial Cognition.",
    "img": "/assets/img/projekte/origami.png",
    "link": "https://geogami.ifgi.de/",
    "topics": [
      "DigitaleBildung",
      "AppEntwicklung",
      "SpatialLearning",
      "Gamification",
      "Forschung"
    ],
    "customer": "Universität Münster",
    "angebote": ["Software", "Forschung", "Beratung", "Lehrmaterial"],
    "year": [2022,2021, 2020, 2019],
    "target": ["Schüler:innen", "Lehrer:innen", "Hochschulen"]
  },
  {
    "name": "senseBox im Futurium",
    "desc": "Integration der senseBox in die Ausstellung und die Veranstaltungsformate des Futuriums. Hackathons, Lehrmaterialien, ein interaktives lebendiges Exponat, künstlerische Datenvisualisierung und Bürgerworskhops.",
    "img": "/assets/img/projekte/Futurium_senseBox-Exponat.jpg",
    "link": "https://futurium.de/de/sense-box",
    "topics": ["DigitaleBildung", "CitizenScience", "Museum", "Exponat", "Bürgerbeteiligung"],
    "customer": "Futurium gGmbH",
    "angebote": ["Software", "Hardware", "Beratung", "Veranstaltung"],
    "year": [2019, 2018],
    "target": ["Schüler:innen", "Bürger:innen"]
  },
  {
    "name": "Landingpages für Bachelor und Master Geoinformatik",
    "desc": "Design und Entwicklung von Wordpress-basierten Landingspages für die Studiengänge Bachelor of Science Geoinformatik und Master of Science Geoinformatics.",
    "img": "/assets/img/projekte/landingpages_ifgi.png",
    "link": "http://bachelor-geoinformatik.de",
    "topics": ["WebDevelopment", "Wordpress", "Hochschule", "WebDesign"],
    "customer": "Universität Münster",
    "angebote": ["Software", "Beratung"],
    "year": [2020],
    "target": ["Student:innen", "Schüler:innen", "Hochschulen"]
  },
  {
    "name": "Reflectories",
    "desc": "Weiterentwicklung der spielbasierten Web-App Reflectories und des Autorentools. Erstellung der Reflectories Wordpress-Seite.",
    "img": "/assets/img/projekte/reflectories.png",
    "link": "https://reflectories.de",
    "topics": [
      "WebDevelopment",
      "Wordpress",
      "HigherEducation",
      "Gamification",
      "DigitalBildung",
      "SDGs",
      "GlobalesLernen"
    ],
    "customer": "Universität Münster",
    "angebote": ["Software"],
    "year": [2021, 2020],
    "target": ["Schüler:innen", "Lehrer:innen", "Hochschulen"]
  },
  {
    "name": "Biogas Datenbank",
    "desc": "Implementierung einer Web-App zur Analyse von Biogasanlagen.",
    "img": "/assets/img/projekte/biogas.jpg",
    "link": "",
    "topics": ["WebDevelopment", "DataScience", "SmartCity"],
    "customer": "FH Münster - University of Applied Sciences",
    "angebote": ["Software"],
    "year": [2020],
    "target": ["Hochschulen", "Städte", "Energiewirtschaft"]
  },
  {
    "name": "Ferienworkshops #DigitaleOstern #DigitalerHerbst",
    "desc": "Konzeption und Durchführung von Ferienworkshops für Kinder von Mitarbeiter:innen der Uni Münster im Alter von 6-10 Jahren zu digitalen Themen wie Programmierung, Scratch, senseBox, Lego.",
    "img": "/assets/img/projekte/digitaleostern.jpg",
    "link": "",
    "topics": ["DigitaleBildung", "Ferienworkshops"],
    "customer": "Universität Münster",
    "angebote": ["Veranstaltung"],
    "year": [2020, 2019],
    "target": ["Schüler:innen", "Hochschulen"]
  },
  {
    "name": "Climate Hackathon",
    "desc": "Konzeption und Durchführung eines digitalen Hackathons zum Thema Klimawandel, Datenvisualisierung und Kunst am Goethe-Institut Glasgow. Ausstattung von Goethe-Instituten mit senseBoxen.",
    "img": "/assets/img/projekte/climate-hackathon.png",
    "link": "https://www.goethe.de/prj/one/en/gea/for/clc.html",
    "topics": ["DigitaleBildung", "CitizenScience", "Klimawandel"],
    "customer": "Junge Tüftler gGmbH, Goethe-Institut",
    "angebote": ["Veranstaltung"],
    "year": [2020],
    "target": ["Bürger:innen", "Student:innen"]
  },
  {
    "name": "Turing Bus Workshops",
    "desc": "Konzeption und Durchführung von mehreren mobilen senseBox-Workshops mit dem Turing Bus.",
    "img": "/assets/img/projekte/turing_bus.png",
    "link": "https://turing-bus.de/",
    "topics": ["DigitaleBildung"],
    "customer": "Gesellschaft für Informatik",
    "angebote": ["Veranstaltung"],
    "year": [2019],
    "target": ["Schüler:innen"]
  },
  {
    "name": "senseBox Space Hackathon",
    "desc": "Organisation und Durchführung eines zweitägigen Hackathons mit Zeltcamp für 50 Kinder und Jugendliche (10-18 Jahre) zum Thema “Space”.",
    "img": "/assets/img/projekte/senseBox_Space_Hackathon.jpg",
    "link": "https://sensebox.de/2018/09/07/hackathon",
    "topics": ["DigitaleBildung", "Hackathon"],
    "customer": "KIMM e.V. Kindermuseum Münster",
    "angebote": ["Veranstaltung"],
    "year": [2018],
    "target": ["Schüler:innen"]
  },
  {
    "name": "Handel-O-Mat",
    "desc": "In der Good News-Litfaßsäule können durch Klicken auf die einzelnen Zeilen Satzteile verändert und so witzige und wirkungsvolle Handlungsideen kombiniert werden.",
    "img": "/assets/img/projekte/Handel-o-mat.png",
    "link": "https://germanwatch.org/de/handel-o-mat",
    "topics": ["GlobalesLernen", "WebDevelopment"],
    "customer": "Germanwatch e.V.",
    "angebote": ["Software"],
    "year": [2020],
    "target": ["Schüler:innen", "Gesellschaft"]
  },
  {
    "name": "CodeWeek-Workshops Berlin",
    "desc": "Konzeption und Durchführung von sechs senseBox-Workshops im Rahmen der CodeWeek in Berlin.",
    "img": "/assets/img/projekte/CodeWeek.jpg",
    "link": "https://junge-tueftler.de/angebot/codeweek-berlin",
    "topics": ["DigitaleBildung"],
    "customer": "Junge Tüftler gGmbH",
    "angebote": ["Veranstaltung"],
    "year": [2020],
    "target": ["Schüler:innen"]
  },
  {
    "name": "CodeWeek-Workshops Bonn",
    "desc": "Konzeption und Durchführung von senseBox Workshops im Rahmen der CodeWeek in Bonn.",
    "img": "/assets/img/projekte/CodeWeek.jpg",
    "link": "https://bonn.codeweek.de/das-programm-2020/veranstaltung/26-gutes-klima",
    "topics": ["DigitaleBildung"],
    "customer": "Telekom Stiftung",
    "angebote": ["Veranstaltung"],
    "year": [2020],
    "target": ["Schüler:innen"]
  },
  {
    "name": "Wetter.Wasser.Waterkant-Workshops",
    "desc": "Konzeption und Durchführung von senseBox-Workshops im Rahmen von Wetter.Wasser.Waterkant in Hamburg.",
    "img": "/assets/img/projekte/wetterwasserwaterkant.png",
    "link": "http://www2020.de/wetter-wasser-waterkant-rueckblick-2020/",
    "topics": ["DigitaleBildung", "Klimawandel"],
    "customer": "Climate Ambassadors - Klimabotschafter e.V.",
    "angebote": ["Veranstaltung"],
    "year": [2020, 2019],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "Klassenzimmer der Zukunft",
    "desc": "senseBox-Workshops im Rahmen des Klassenzimmers der Zukunft auf der Frankfurter Buchmesse.",
    "img": "/assets/img/projekte/klassenzimmerderzukunft.jpg",
    "link": "",
    "topics": ["DigitaleBildung"],
    "customer": "ELIG Foundation gGmbH",
    "angebote": ["Veranstaltung"],
    "year": [2019],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "openSenseMap Feature-Layer",
    "desc": "Erstellung von ArcGIS Feature Layern mit Daten der openSenseMap. Neben statischen Datensätzen werden zwei Layer regelmäßig mit Live-Daten der openSenseMap aktualisiert.",
    "img": "/assets/img/projekte/esri_interpolation.png",
    "link": "",
    "topics": ["DigitaleBildung", "WebGIS", "Geoinformation"],
    "customer": "ESRI Deutschland",
    "angebote": ["Software"],
    "year": [2020],
    "target": ["Schüler:innen"]
  },
  {
    "name": "Indoor Lokalisation von Krankenhausbetten",
    "desc": "Prototypische Implementierung eines Systems zur Indoor-Lokalisation von Krankenhausbetten mittels Funksendern.",
    "img": "/assets/img/projekte/stiegelmeyer.png",
    "link": "",
    "topics": ["Gesundheit", "Pflege", "Sensors"],
    "customer": "Stiegelmeyer",
    "angebote": ["Software"],
    "year": [2019],
    "target": ["Krankenhäuser", "Pflege"]
  },
  {
    "name": "TTN-Mapper für städtisches LoRa-Netz",
    "desc": "Entwicklung von GPS-LoRaWAN-Mappern zur Messung der Empfangsstärke von LoRaWAN-Signalen.",
    "img": "/assets/img/projekte/stadtwerke-ttn-mapper.jpg",
    "link": "",
    "topics": ["SmartCity", "LoRa"],
    "customer": "Stadtwerke Münster",
    "angebote": ["Software", "Hardware"],
    "year": [2020],
    "target": ["Professionals", "Städte"]
  },
  {
    "name": "Städtisches autarkes Sensornetzerk in Enschede",
    "desc": "Hardware für ein LoRa-Sensornetzwerk mit ca. 100 autarken senseBox-Messstationen zur Klimabeobachtung in Enschede.",
    "img": "/assets/img/projekte/enschede.png",
    "link": "",
    "topics": ["Forschung", "SmartCity"],
    "customer": "Universität Twente",
    "angebote": ["Hardware"],
    "year": [2020],
    "target": ["Professionals", "Wissenschaft", "Hochschulen"]
  },
  {
    "name": "senseBox-Wettbewerb Hamburg",
    "desc": "Konzeption und Durchführung des senseBox-Wettbewerbs Hamburg, bei dem 10 Schulen für die Umsetzung innovativer Projekte mit einer senseBox:edu Klassenkiste prämiert wurden. Die beteiligten Lehrer:innen erhielten in einem Workshop eine Einführung in die senseBox.",
    "img": "/assets/img/projekte/senseboxwettbewerb.png",
    "link": "",
    "topics": ["DigitaleBildung", "CitizenScience"],
    "customer": "Körber Stiftung",
    "angebote": ["Hardware", "Veranstaltung", "Beratung"],
    "year": [2019],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "Klima findet Stadt",
    "desc": "Förderung von Umweltbildung und offener Jugendarbeit im MINT-Bereich im Rhein-Sieg-Kreis mit der Kernregion Bonn. Workshops in Jugendhilfeeinrichtungen und weiterführende Schulen im Rhein-Sieg-Kreis mit der Kernregion Bonn.",
    "img": "/assets/img/projekte/klimafindetstadt.jpg",
    "link": "https://www.telekom-stiftung.de/klima-findet-stadt",
    "topics": ["DigitaleBildung", "CitizenScience"],
    "customer": "Telekom Stiftung",
    "angebote": ["Hardware", "Veranstaltung", "Lehrmaterial"],
    "year": [2019, 2018],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "Mobiles Umweltlabor",
    "desc": "Entwicklung eines Bluetooth-Moduls für die senseBox:edu zur Anknüpfung an die phyphox-App. Anpassungen an der Blockly-Programmierumgebung, Entwicklung von Lehrmaterialien und Testworkshops in Schulen.",
    "img": "/assets/img/projekte/mobilesumweltlabor.png",
    "link": "",
    "topics": ["DigitaleBildung"],
    "customer": "Telekom Stiftung",
    "angebote": ["Hardware", "Software", "Veranstaltung", "Lehrmaterial"],
    "year": [2021, 2020],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "Make Climate",
    "desc": "Hackathons, Workshops und Schulprojekte zum Thema Umweltsensorik mit Open-Source-Elektronik, mit dem Ziel ein offenes Sensornetzwerk aufzubauen, welches von Schulen und der Bürgerschaft betrieben wird.",
    "img": "/assets/img/projekte/MakeClimate.jpg",
    "link": "",
    "topics": ["DigitaleBildung", "CitizenScience"],
    "customer": "Telekom Stiftung",
    "angebote": ["Veranstaltung"],
    "year": [2020, 2019],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "senseBox Fortbildungen",
    "desc": "Durchführung von senseBox Lehrerfortbildungen und Veranstaltungen für Informatik-Lehramtsstudierende aus Baden-Würtemberg.",
    "img": "/assets/img/projekte/MINT-Kongress.jpg",
    "link": "",
    "topics": ["DigitaleBildung"],
    "customer": "HOPP Foundation",
    "angebote": ["Veranstaltung"],
    "year": [2020, 2010],
    "target": ["Student:innen", "Lehrer:innen"]
  },
  {
    "name": "senseBox Online Video Tutorials",
    "desc": "Produktion von Online-Video-Tutorials für YouTube für den Einstieg in die Arbeit mit der senseBox.",
    "img": "/assets/img/projekte/senseboxonlinevideotutorials.png",
    "link": "https://unterrichtsmaterialien.hopp-foundation.de/videos?grades=&tag%5B50%5D=1&filtern=&q=",
    "topics": ["DigitaleBildung"],
    "customer": "HOPP Foundation",
    "angebote": ["Lehrmaterial"],
    "year": [2020],
    "target": ["Student:innen", "Lehrer:innen", "Schüler:innen"]
  },
  {
    "name": "Aufbau LoRa-Sensornetzwerk",
    "desc": "Offenes Sensornetzwerk mit 50 LoRa-Feldstationen, ausgerüstet mit Klima- und Bodensensoren.",
    "img": "/assets/img/projekte/lora_tuberlin.png",
    "link": "",
    "topics": ["Forschung", "SmartCity", "Klimawandel"],
    "customer": "TU Berlin - Institut für Ökologie",
    "angebote": ["Hardware"],
    "year": [2019, 2020],
    "target": ["Wissenschaftler:innen"]
  },
  {
    "name": "MINT-Kongress Workshop",
    "desc": "Bürgerworkshops zu Open-Source-Umweltsensorik und IoT bei den MINT-Tagen.",
    "img": "/assets/img/projekte/MINT-Kongress.jpg",
    "link": "",
    "topics": ["DigitaleBildung", "MINT", "IoT", "CitizenScience"],
    "customer": "Stadtbibliothek Köln",
    "angebote": ["Veranstaltung"],
    "year": [2020, 2019],
    "target": ["Bürger:innen"]
  },
  {
    "name": "LoRaWAN-SensorKit",
    "desc": "Entwicklung der Firmware für ein customized LoRaWAN-Device.",
    "img": "/assets/img/logos/logo_small_text_orange.svg",
    "link": "",
    "topics": ["SmartCity", "LoRa"],
    "customer": "Smart City Solutions",
    "angebote": ["Hardware"],
    "year": [2019],
    "target": ["Städte"]
  },
  {
    "name": "Smart City Dashboard",
    "desc": "Entwicklung eines Smart City Dashboards.",
    "img": "/assets/img/projekte/smart-city-dashboard.png",
    "link": "https://dashboard.smartcity.ms/",
    "topics": ["SmartCity", "WebDevelopment", "OpenData"],
    "customer": "Stadt Münster, Stabsstelle Smart City",
    "angebote": ["Hardware", "Software"],
    "year": [2021,2022,2023,2024],
    "target": ["Städte", "Bürger:innen"]
  },
  {
    "name": "Story Map for international graduate students",
    "desc": "Entwicklung einer Story-Map-Webseite zur Orientierung neuer internationaler Doktoranden.",
    "img": "/assets/img/projekte/StoryMap WWU Graduate Centre.PNG",
    "link": "https://go.wwu.de/gcstorymap",
    "topics": ["DigitaleBildung", "WebDevelopment"],
    "customer": "Universität, Graduate Center",
    "angebote": ["Software", "Lehrmaterial"],
    "year": [2021],
    "target": ["Student:innen", "Hochschulen"]
  },
  {
    "name": "Hackathon zum Digitalen Klassenzimmer",
    "desc": "Hackathon am Annette-von-Droste-Hülshoff-Gymnasium Dülmen zum Auftakt des Digitalen Klassenzimmers.",
    "img": "/assets/img/logos/logo_small_text_orange.svg",
    "link": "",
    "topics": ["DigitaleBildung", "Hackathon"],
    "customer": "DigitalCampus Nordkirchen",
    "angebote": ["Veranstaltung"],
    "year": [2019],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "webGIS.nrw",
    "desc": "Weiterentwicklung eines webbasierten Geographischen Informationssystems für den Bildungsbereich",
    "img": "/assets/img/projekte/webgis-nrw.png",
    "link": "https://webgis.nrw",
    "topics": ["DigitaleBildung", "WebDevelopment", "GIS"],
    "customer": "Universität Münster, Institut für Geoinformatik",
    "angebote": ["Software"],
    "year": [2021],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "Digitale Messe JurStart",
    "desc": "Entwicklung und Durchführung einer digitalen Karrierestartmesse für Jura-Absolvent:innen",
    "img": "/assets/img/projekte/jurstart.jpg",
    "link": "https://jurstart.de",
    "topics": ["DigitaleBildung", "WebDevelopment", "WebDesign"],
    "customer": "JurStart GmbH",
    "angebote": ["Software", "Veranstaltung"],
    "year": [2021],
    "target": ["Student:innen","Kanzleien"]
  },
  {
    "name": "welt.weit.virtuell - Globales Lernen mit digitalen Medien",
    "desc": "Entwicklung der kartenbasierten Storytelling-Plattform mapstories und Einsatz in Reallaboren",
    "img": "/assets/img/projekte/vamos_logo_4c.jpg",
    "link": "https://mapstories.de",
    "topics": ["DigitaleBildung", "WebDevelopment", "GlobalesLernen"],
    "customer": "Vamos e.V.",
    "angebote": ["Software", "Veranstaltung"],
    "year": [2021],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
    {
    "name": "KlimaDatenSchule",
    "desc": "Entwicklung einer Web App und Beratung von BildungsCent e.V. und mediale pfade – Verein für Medienbildung e.V.",
    "img": "/assets/img/projekte/KlimaDatenSchule.png",
    "link": "https://klimadatenschule.de",
    "topics": ["DigitaleBildung", "WebDevelopment", "Klimawandel"],
    "customer": "BildungsCent e.V., mediale pfade – Verein für Medienbildung e.V.",
    "angebote": ["Software", "Beratung"],
    "year": [2021],
    "target": ["Schüler:innen", "Lehrer:innen"]
  },
  {
    "name": "senseBox:bike - Futurium",
    "desc": "Entwicklung einer mobilen senseBox für Fahrräder, Videotutorials, Makeathons und Veranstaltungen und eines Exponats",
    "img": "/assets/img/projekte/senseBox-bike.png",
    "link": "https://futurium.de/de/senseboxbike",
    "topics": ["DigitaleBildung", "CitizenScience"],
    "customer": "Futurium gGmbH",
    "angebote": ["Hardware", "Software", "Veranstaltung", "Lehrmaterial"],
    "year": [2021],
    "target": ["Bürger:innen"]
  }
]
