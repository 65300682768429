<div class="site black-section" *ngIf="job">
  <div class="re-top-space-holder"></div>
  <div class="stripes-container">
    <div class="stripes">
      <div class="re-offset-container">
        <div class="project-container">
          <div class="container padding-6-resp">
            <div class="white-section project-description padding-6-resp">
              <a class="back-link" href="/jobs"
                ><img src="./../../../../assets/img/logos/logo_black.svg" />
                {{ "BACK" | translate }}
              </a>
              <div class="py-5">
                <markdown [src]="job" class="markdown-container"></markdown>

                <div class="benefits-section mt-6">
                  <h1 class="has-text-centered mb-6">
                    {{ "Vorteile" | translate }}
                  </h1>
                  <div class="columns is-multiline is-centered">
                    <div
                      class="column is-12-mobile is-6-tablet is-3-desktop"
                      *ngFor="let benefit of benefits"
                    >
                      <a href="https://pad.sensebox.de/os7HMcU6QGOBGuC4u-KPeg">
                        <div class="has-text-centered">
                          <img
                            src="./../../../../assets/img/benefits/{{
                              benefit.imgUrl
                            }}"
                            alt="{{ benefit.title }}"
                            style="
                              width: 70px;
                              height: 70px;
                              margin-bottom: 0.2rem;
                            "
                          />
                          <h3 class="is-5">
                            <span style="color: #eb5c37">{{
                              benefit.title
                            }}</span>
                          </h3>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
