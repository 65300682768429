[
  {
    "title": "JobRad",
    "imgUrl": "bike.png"
  },
  {
    "title": "Fitness",
    "imgUrl": "gym.png"
  },
  {
    "title": "Educational Leave",
    "imgUrl": "Book.png"
  },
  {
    "title": "Training",
    "imgUrl": "training.png"
  }


]

