[
  {
    "title": "Hardware",
    "title2": "senseBox und Rapid Prototyping für IoT und Sensorik",
    "description": "Die Cititzen Science Umweltmessstation zum Selberbauen – für den Einsatz zuhause, in Schulen, Hochschulen und anderen Bildungs- und Forschungseinrichtungen, sowie in Unternehmen, Städten oder Gemeinden. Konfigurieren Sie die Station mit Sensorik nach Ihren Wünschen und übertragen Sie die Umweltdaten mit LoRa, Wifi oder Lan ins Internet. Wir entwickeln passgenaue Lösungen für Ihre Fragestellung rund um Sensoren und das Internet of Things:" ,
    "list1": "individuelle Anpassung der senseBox nach Ihrer Fragestellung",
    "list2": "Entwicklung alternativer Datenübertragungsmethoden",
    "list3": "Rapid Prototyping",
    "end":  "Entdecken Sie unsere Projektpallette zum Thema Hardware und alles rund um die senseBox:",
    "link": "portfolio?angebot=Hardware",
    "linkText": "Portfolio ansehen",
    "link2": "https://www.sensebox.kaufen",
    "link2Text": "senseBox Shop",
    "link3": "https://www.sensebox.de",
    "link3Text": "senseBox Projekt",
    "image": "/assets/img/angebote/hardware.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Software",
    "title2": "Digitale Bildung, Citizen Science, Crowdsourcing",
    "description": "Ob webbasiertes Geoinformationssystem, individuelles Lernmanagementsystem, Datenanalysesoftware, Forschungsdateninfrastruktur, LernApp oder Citizen Science App. Unser erfahrenes Team konzipiert, gestaltet und entwickelt Lösungen für Bildung, Wissenschaft und Partizipation. Entdecken Sie unsere Projektpallete zum Thema Software und sprechen Sie uns an.",
    "link": "portfolio?angebot=Software",
    "linkText": "Portfolio ansehen",
    "image": "/assets/img/angebote/software.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Veranstaltungen",
    "title2": "Schulungen, Workshops und Hackathons",
    "description": "Wir organisieren und veranstalten Events für Kinder, Jugendliche und Erwachsene. Zum Beispiel:",
    "list1": "Hackathon mit Zeltcamp an einer Universität",
    "list2": "Ferienworkshops für Kinder in Unternehmen",
    "list3": "Coding-Workshops in Museen oder Bibliotheken",
    "list4": "Lehrer*innenfortbildung in der Schule",
    "list5": "Bürgerwerkstatt im Repair-Cafe",
    "list6": "Online-Hackathon",
    "end": "Schauen Sie sich unsere bisherigen Veranstaltungen an und kontaktieren Sie uns.",
    "linkText": "Portfolio ansehen",
    "link": "portfolio?angebot=Veranstaltung",
    "link2": "workshops",
    "link2Text": "Mehr Informationen und Kontakt",
    "image": "/assets/img/angebote/veranstaltungen.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Lern- und Lehrmaterialien",
    "title2": "Videos, Tutorials, didaktische Konzepte",
    "description": "Für verschiedene Zielgruppen entwickeln wir (wenn möglich unter offenen Lizenzen stehende) Lehr- und Lernmaterialien, sog. Open Educational Resources. Das können statische oder interaktive Dokumente und Tutorials im Sinne von 'Arbeitsblättern' sein, YouTube Video-Tutorials, Lehrbücher, Lernkarten oder Content für andere Anwendungen. Unsere Materialien sind in Schulen, Museeen und in Projekten diverser Stiftungen im Einsatz. Schauen Sie selbst und entwickeln Sie Ihr Material gemeinsam mit uns.",
    "link": "portfolio?angebot=Lehrmaterial",
    "linkText": "Portfolio ansehen",
    "image": "/assets/img/angebote/lernmaterial.jpeg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Forschung",
    "title2": "Unser Beitrag zur Wissenschaft",
    "description": "Als Start-Up Unternehmen (KMU) sind wir Partner in mehreren Forschugnskonsortien und übernehmen vielfältige Aufgaben und Unteraufträge in Forschungsprojekten unterschliedlicher Fördergeldgeber, wie dem Bundesministerium für Bildung- und Forschung, der EU oder dem Bundesministerium füt Wirtschaft und Innovation. Unsere Forschugnsmethoden umfassen:",
    "list1": "Design und Durchführung von Nutzer*innen-Studien (z.B. Usability-Studien)",
    "list2": "Entwicklung von Forschungssoftware, cloudbasierten Forschungsdateninfragstrukturen und -banken.",
    "list3": "Entwicklung und Evaluation von Umweltsensorik-Systemen",
    "list4": "Geostatistische Datenanalyse",
    "list5": "Kommunikation und Dissemination von Forschungsergebnissen",
    "end": "Schauen Sie sich unsere Forschungsprojekte an und sprechen Sie uns an.",
    "link": "/forschung",
    "linkText": "Unsere Forschungsprojekte",
    "link2": "portfolio?angebot=Forschung",
    "link2Text": "Portfolio ansehen",
    "image": "/assets/img/angebote/forschung.jpg",
    "underscoreStyle": "dark"
  },
  {
    "title": "Beratung",
    "title2": "Strategie, Konzepte, Umsetzung",
    "description": "Wir beraten Sie und entwickeln gemeinsam mit Ihnen eine Digtialisierungsstrategie passgenau zu Ihrer Fragestellung. Mit unserer Erfahrung bei der Umsetzung von Projekten aus den Bereichen Digtiale Bildung, Crowdsourcing, Citizen Science, Datenanalyse, Smart City, Internet of Things und unserem Hintergrund im Forschungskontext, unterstützen wir Sie bei Ihrem Vorhaben. ",
    "link": "portfolio?angebot=Beratung",
    "linkText": "Portfolio ansehen",
    "image": "/assets/img/angebote/beratung.jpg",
    "underscoreStyle": "dark"
  }
  
]
