[
  {
    "name": "Dr. Thomas Bartoschek",
    "position": "CEO",
    "title": "Ph.D. Geoinformatics",
    "tags": ["Science", "Education", "Speaking", "Sales", "UX", "Vision"],
    "email": "t.bartoschek@reedu.de",
    "photo": "/assets/img/team/barto_new.jpg"
  },
  {
    "name": "Jan Wirwahn",
    "position": "Developer & Co-Founder",
    "title": "M.Sc. Geoinformatics",
    "tags": ["Open Source Hardware", "IoT", "Education", "Camping & Kayaking"],
    "email": "j.wirwahn@reedu.de",
    "photo": "/assets/img/team/jan_new.jpg"
  },
  {
    "name": "Umut Taş",
    "position": "CEO & Developer",
    "title": "M.Sc. Geoinformatics",
    "tags": [
      "Data Visualization",
      "Maps",
      "Open Data",
      "Angular",
      "Node",
      "React"
    ],
    "email": "u.tas@reedu.de",
    "photo": "/assets/img/team/umut_new.jpg"
  },
  {
    "name": "Felix Erdmann",
    "position": "Developer",
    "title": "M.Sc. Geoinformatics",
    "tags": ["Maps", "React", "Node", "Mongo", "IoT", "GIS"],
    "email": "f.erdmann@reedu.de",
    "photo": "/assets/img/team/felix_new.jpg"
  },
  {
    "name": "Verena Witte",
    "position": "Education",
    "title": "M.Ed. Mathematics & Geographie",
    "tags": ["Education", "Workshops", "OER", "Projectdays"],
    "email": "v.witte@reedu.de",
    "photo": "/assets/img/team/verena_new.jpg"
  },
  {
    "name": "Gina Buchwald-Chassée",
    "position": "Communication",
    "title": "M.Sc. Communication Science",
    "tags": ["Corporate Communications", "Social Media"],
    "photo": "/assets/img/team/gina_new.jpg"
  },
  {
    "name": "Eduardo Candeias Schneider",
    "position": "Student assistant",
    "title": "B.A. Education science & information processing",
    "tags": ["Education", "Workshops", "OER"],
    "photo": "/assets/img/team/EduardoCandelas_067.JPG"
  },
  {
    "name": "Artur Kim Shum",
    "position": "Innovation Designer",
    "title": "B.A. Architektur und Stadtplanung",
    "tags": ["UX", "Prototyping", "Data Visualization", "Open Data"],
    "photo": "/assets/img/team/KIMSHUM.png"
  },
  {
    "name": "Eric Thieme-Garmann",
    "position": "Developer",
    "title": "M.Sc. Geoinformatics",
    "tags": ["IoT", "WebDevelopment"],
    "photo": "/assets/img/team/Eric.png"
  },
  {
    "name": "Sergey Mukhametov",
    "position": "Co-Founder",
    "title": "B.Sc. Geoinformatics",
    "tags": ["Science", "Museums & Exhibitions"],
    "email": "s.mukhametov@reedu.de",
    "photo": "/assets/img/team/sergey_new.jpg"
  },
  {
    "name": "David Fehrenbach",
    "position": "Co-Founder",
    "title": "M.Sc. Business Management",
    "tags": ["Business operations"],
    "photo": "/assets/img/team/david_new.jpg"
  },
  {
    "name": "Georgi Semov",
    "position": "Developer",
    "title": "Apprentice",
    "tags": ["IoT", "Arduino"],
    "photo": "/assets/img/team/Georgi.png"
  },
  {
    "name": "Paula Scharf",
    "position": "Developer",
    "title": "M.Sc. Geoinformatics",
    "tags": ["Web development","IoT", "AI"],
    "photo": "/assets/img/team/Paula_Scharf_Foto.png"
  },
     {
    "name": "Kieran Galbraith",
    "position": "Student assistant, Software Developer",
    "title": "",
    "tags": ["Python", "Java Script"],
    "photo": "/assets/img/team/Kieran.jpg"
  },
  {
    "name": "Ronja Federer",
    "position": "Office Management",
    "title": "&#8203;",
    "tags": ["Organization", "Yoga"],
    "email": "r.federer@reedu.de",
    "photo": "/assets/img/team/ronja.jpg"
  },
  {
    "name": "Mostafa Kandel",
    "position": "Developer",
    "title": "M.Sc. Electrical Engineering",
    "tags": ["Python", "Node", "React","JavaScript"],
    "photo": "/assets/img/team/mostafa.jpg"
  },
  {
    "name": "Maria Zadnepryanets",
    "position": "Developer",
    "title": "M.Sc. Engineering and Technology",
    "tags": ["React", "JavaScript"],
    "photo": "/assets/img/team/maria.jpg"
  },
  {
    "name": "Matteo Weickert",
    "position": "Bildung",
    "title": "Werkstudent",
    "tags": ["AGs", "Projekttage", "Machine Learning"],
    "photo": "/assets/img/team/matteo.jpg"
  }
]
