[
  {
    "title":"Teil der Digtial Engagiert 2022 Förderinitiative ",
    "image":"assets/img/awards/logo_digitalengagiert.jpeg",
    "desc":"Am 10. Februar 2022 hat die Jury nach einer einmonatigen Bewerbungsphase aus über 160 eingereichten Projekten zehn davon für die diesjährige Förderrunde, die den Fokus auf die digitale Bildung benachteiligter Kinder, Jugendlicher und Erwachsener legt, ausgewählt. Die zehn Teams werden in den nächsten vier Monaten finanziell unterstützt, von einer Coachin oder einem Coach individuell betreut und beraten, und erhalten die Möglichkeit, an einer Vielzahl von Workshops mit Expert*innen aus Bildung, Tech und Unternehmertum teilzunehmen.",
    "link":"https://www.digitalengagiert.de/blog/kick-off-der-5-forderrunde-von-digitalengagiert-die-teams-stellen-sich-vor"
   },
  {
   "title":"2. Platz beim productronica Fast Forward Award 2021",
   "image":"assets/img/awards/productronica2021.jpg",
   "desc":"Beim productronica fast forward 2021, die Start-up-Plattform powered by Elektor, haben Start-ups die Möglichkeit, sich und ihre Technologien vorzustellen. Mit unserem eingereichten Beitrag konnten wir die Jury überzeugen und den 2. Platz belegen.",
   "link":"https://www.productronica.com/de/programm/accelerating-talents/productronica-fast-forward/"
  },
  {
   "title":"3. Platz beim NRW-Medienpreis für entwicklungspolitisches Engagement",
   "image":"assets/img/awards/NRW-Medienpreis.png",
   "desc":"Das von Vamos e.V. Münster zusammen mit Schüler:innen der Mathilde-Anneke Gesamtschule Münster (MAG) erstellte innovative Modul „Der Stoff, aus dem die Träume sind“ auf der von uns entwickelten Plattform Mapstories (https://mapstories.de/) hat den 3. Platz beim NRW-Medienpreis gewonnen.",
   "link":"https://filmfestival.cologne/nrw-medienpreis-f%C3%BCr-entwicklungspolitisches-engagement"
  },
  {
   "title":"Out-of-the-Box NRW Finalist",
   "image":"assets/img/awards/ootb.svg",
   "desc":"Unser Start-Up re:edu stand beim Wettbewerb OUT OF THE BOX.NRW 2021 des Ministeriums für Wirtschaft, Innovation, Digitalisierung und Energie und der NRW.Bank gemeinsam mit 10 weiteren Start-Ups im Finale, um das beste digitale Start-Up in NRW zu werden.",
   "link":"https://www.youtube.com/watch?v=6nYdi4bdfzo&t=4s"
  },
  {
    "title": "Falling Walls Finalist 2020",
    "image": "assets/img/awards/falling_walls_2020.jpg",
    "desc": "Unser Geschäftsführer Dr. Thomas Bartoschek wurde 2020 zum Finalisten des Falling Walls / Berlin Science Week Festivals bei den Science Breakthroughs of the Year in der Kategorie \"Digital Education\" ausgewählt.",
    "link":"https://falling-walls.com/people/thomas-bartoschek/"
  },
  {
    "title": "1. Platz in der Kategorie Deutschland des Start-ups Connected Awards der AHK Sao Paulo",
    "image": "assets/img/awards/ahk-sao-paulo.svg",
    "desc": "re:edu hat beim Wettbewerb \"Startups Connected\" der Außenhandelskammer São Paulo 2020 den 1. Platz in der Kategorie \"Deutschland\" gewonnen - als Science Start-Up mit dem größten Potenzial für den brasilianischen Markt. Unser Head of Education Björn Guntermann hat den Preis in São Paulo entgegengenommen und konnte die senseBox auf einer Tour durch Brasilien an vielen Hochschulen vorstellen.", 
    "link":"https://www.myscience.de/news/wire/auszeichnung_fuer_start_up_der_universitaet_muenster_re-2019-uni-muenster"
  },
  {
    "title": "1. Platz beim Pecha Kucha Pitch auf der Learntec 2019",
    "image": "assets/img/awards/learntec.png",
    "desc": "Auf der Learntec, einer internationalen Fachmesse rund um die Digitalisierung der Lern- und Arbeitswelt in Schule, Hochschule und Beruf, überzeugten wir im Jahr 2019 die Jury mit unserem Beitrag zum Pecha Kucha-Pitch für EdTech Start-Ups und holten den ersten Platz.", 
    "link":"http://www.lukas-forscherland.de/service/nachrichten/detailansicht/learntec-2019-virtual-reality-ki-und-frischer-start-up-wind.html"
  },
  {
    "title": "2. Platz beim delina2019 Innovationspreis für digitale Bildung",
    "image": "assets/img/awards/delina2019.jpg",
    "desc": "Beim delina Innovationspreis für Digitale Bildung erreichten wir mit unserer Bewerbung den zweiten Platz in der Kategorie Hochschule.",
    "link": "https://www.learntec.de/de/programm/rahmen-und-ausstellerprogramm/preisverleihungen/"
  },
  {
    "title": "Electronica 2019",
    "image": "assets/img/awards/electronica.jpg",
    "desc": "Beim Start-Up Pitch auf der Electronica, Messe und Konferenz der Elektronik, 2018 in München waren wir im Finale vertreten.", 
    "link": "https://www.elektormagazine.com/news/electronica-fast-forward-2018-the-startup-platform-pitch-schedule"
  },
  {
    "title": "Sybille-Hahne-Nachwuchsgründerpreis 2018 - Publikumspreis",
    "desc": "Beim Sybille-Hahne-Nachwuchsgründerpreis gewannen wir den Publikumspreis mit unserem Konzept für re:edu - damaliger Projekttitel: senseBox/eduSense.", 
    "link": "https://www.uni-muenster.de/Ausgruendungen/preise/gp2017.html#anchor112"
  },
  {
    "title": "ERCIS Launch Pad 2017 - Bestes E-Commerce-Geschäftsmodell",
    "image": "assets/img/awards/launchpad.png",
    "desc": "Mit unserem in Gründung befindlichen Vorhaben mit dem Projekttitel \"eduSense\" (jetzt re:edu) und ganz unter unserem Motto \"Full Stack Digital Education\" gewannen wir beim ERCIS Launchpad 2017 den PayPal Sonderpreis für das beste E-Commerce Geschäftsmodell und eine Unterstützung von PayPal bei unserer Shop-Integration.", 
    "link": "http://2017.ercis-launchpad.de/winners"
  },
  {
    "title": "CeBIT Innovation Award 2017",
    "image": "assets/img/awards/cebit-innovation-award-2017.jpg",
    "desc": "Der Sonderpreis für \"Digitales Lehren und Lernen\" beim CeBIT Innovation Award 2017 ging an die senseBox - noch bevor wir mit der Planung für re:edu begonnen haben.", 
    "link": "https://www.uni-muenster.de/news/view.php?cmdid=8805"
  }
]
